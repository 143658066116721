export default {
  'event.status.param':
    '{status,select, 1 {Not started} 2 {In progress} 3 {Ended} other {Undefined}}',
  'event.name': 'Event',
  'event.listAction': 'List of events',
  'event.add': 'Add event',
  'event.buttonSubmit': 'Add',
  'event.buttonEdit': 'Update',
  'event.time': 'Time',
  'event.startDay': 'From date',
  'event.endDay': 'To date',
  'event.view': 'View event details',
  'event.edit': 'Update event',
  'event.btnViewMember': 'Member list',
  'event.listMember': 'Member list',
  'event.totalMember': 'Total members',
  'event.totalMemberActive': 'Number of active members',
  'event.totalMemberNotActive': 'Number of inactive members',
  'event.title': 'Event name',
  'event.timeAction': 'Event time',
  'event.content': 'Event content',
  'event.meritBadgeIds': 'Merit badges',
  'event.score': 'Score',
  'event.clubIds': 'Members participating',
  'event.validatorTitle': 'Event name cannot be empty',
  'event.validatorContent': 'Event content cannot be empty',
  'event.startTime': 'Start Time',
  'event.endTime': 'End Time',
  'event.creator': 'Creator',
  'event.status': 'Status',
  'event.totalMemberTable': 'Total members',
  'event.allMember': 'All members',
  'event.delete.title': 'Delete event',
  'event.delete.content': 'Do you want to delete this event?',
  'event.non-delete.title': 'Can not delete',
  'event.non-delete.content': 'Can not delete event had started',
  'event.validatorTime': 'Start time must greater than now',
  'actionChild.code': 'Member code',
  'actionChild.name': 'Member name',
  'actionChild.emailAddress': 'Email',
  '	actionChild.register': 'Registration status',
  'event.grade': 'Grade',
  'event.unionOfDistrict': 'Union district',
  'event.detachment': 'Detachment',
  'event.createSource': 'Create source',
  'event.tableChild.title': 'Child event name',
  'event.tableChild.meritBadgeIds': 'Merit',
  'event.tableChild.score': 'Score',
  'event.tableChild.startTime': 'Start time',
  'event.tableChild.endTime': 'End time',
  'event.tableChild.grades': 'Grade',
  'event.tableChild.status': 'Status',
  'actionChild.status.param':
    '{status,select, 1 {Not confirm} 2 {Confirmed} 3 {Reject} other {Không xác định}}',
  'actionChild.confirm': 'Status confirm',
  'event.workunits': 'Class',
  'actionChild.confirmTime': 'Confirm time',
  'event.list-member': 'List of members',
  'event.scoreCollected': 'Score',
  'event.scoreDay': 'Date',
  'laudatory.modalHistory': 'Score history',
  'event.address': 'Address',
  'event.attach': 'Attach file',
  'event.upload.title': 'Choose attach file',
  ////////////// add for HCA
  'event.action': 'Action',
  'event.executiveCommittee': 'Executive committee',
  'event.memberUnit': 'Unit member',
  'event.linked': 'Linked member',
  'event.memberPersonal': 'Personal member',
  'event.partnerEnterprise': 'Enterprise partner',
  'event.partnerPersonal': 'Partner personal',
  'event.member': 'Member',
  'event.maxFile': 'Unable to add more than 3 files at the same time',
  'event.emailTemplate.title': 'Email Template',
  'event.emailTemplate.choose': 'Choose template',
  'event.emailTemplate.add': 'Add template',
  'event.emailTemplate.reset': 'Reload',
  'event.joinObjectHas': 'Currently has ',
  'event.joinObject': 'participating objects',
  'event.listObject': 'Select object list',
  'event.listObject.view': 'See detailed list of objects',
  'event.landingPage.link': 'Link',
  'event.landingPage.preview': 'Preview Landing Page',
  'event.landingPage.add': 'Add Landing Page',
  'event.landingPage.update': 'Update Landing Page',
  'event.designLandingPage': 'Design landing Page',
  'event.designLandingPage.subtitle': 'Please select the Landing Page template you want to use',
  'event.method': 'Send ticket method',
  'event.method.label': 'Choose how to send tickets',
  'event.email.repeat': 'Send a reminder email',
  'event.email.label.repeat': 'Repeat first',
  'event.email.label.no-repeat': 'Do not repeat',
  'event.emailRepeat.day': 'Choose day',
  'event.day': 'Day',
  'event.info': 'Event detail',
  'event.participantCount': 'Number of people invited',
  'event.registerCount': 'Number of people registered to participate',
  'event.method-1': 'Submit tickets after successful event creation',
  'event.method-2': 'Submit tickets after registering for the event',
  'event.detail.invited': 'Selected object',
  'event.detail.subscriber': 'Registered subject',
  'event.detail.heading': 'List of participants',
  'actionChild.registerStatus': 'Register Status',
  'event.totalRegister': 'Total number of subscribers',
  'actionChild.qr': 'QR code',
  'actionChild.registrationPeriod': 'Registration period',
  'actionChild.qrCode': 'QR Code',
  'activities.executiveCommittee': 'Executive committee',
  'activities.memberUnit': 'Unit member',
  'activities.linked': 'Linked member',
  'activities.memberPersonal': 'Personal member',
  'activities.partnerEnterprise': 'Enterprise partner',
  'activities.partnerPersonal': 'Partner personal',
  'activities.member': 'Member',
  'event.banner': 'Banner Email to send tickets',
  'event.error.empty': 'Select email banner to send tickets',
  'event.type.member.1': 'Unit Member',
  'event.type.member.2': 'Linked Member',
  'event.type.member.3': 'Enterprise Partner',
  'event.type.member.4': 'Executive Committee',
  'event.type.member.5': 'Club',
  'event.type.member.6': 'Personal Member',
  'event.type.member.7': 'Partner Personal',
  'event.listMember.title.pronouns': 'Pronouns',
  'event.listMember.title.companyName': 'Company name',
  'event.listMember.title.jobTitle': 'Job title',
  'event.listMember.title.organizationType': 'Type of activity',
  'event.listMember.title.businessField': 'Field of activity',
  'event.listMember.pronouns.1': 'Mr',
  'event.listMember.pronouns.2': 'Mrs',
  'event.listMember.businessField.1': 'Information Technology – Communications',
  'event.listMember.businessField.2': 'Transportation',
  'event.listMember.businessField.3': 'Education – Training',
  'event.listMember.businessField.4': 'Healthcare',
  'event.listMember.businessField.5': 'Tourism',
  'event.listMember.businessField.6': 'Retail',
  'event.listMember.businessField.7': 'F&B',
  'event.listMember.businessField.8': 'Entertainment',
  'event.listMember.businessField.9': 'Fashion',
  'event.listMember.businessField.10': 'Logistics',
  'event.listMember.businessField.11': 'Industry – Mining – Manufacturing – Processing',
  'event.listMember.businessField.12': 'Finance – Banking – Insurance',
  'event.listMember.businessField.13': 'Real Estate – Construction',
  'event.listMember.businessField.14': 'Agriculture – High-Tech Agriculture',
  'event.listMember.businessField.15': 'Resources – Environment',
  'event.listMember.businessField.16': 'Others',
  'event.listMember.organizationType.1': 'Government agency/ Domestic association',
  'event.listMember.organizationType.2': 'Diplomatic corps/ International organization',
  'event.listMember.organizationType.3': 'Non-governmental organization/ Non-profit organization',
  'event.listMember.organizationType.4': 'Company/ Factory/ Manufacturing plant',
  'event.listMember.organizationType.5': 'Trade/ Service company',
  'event.listMember.organizationType.6': 'Research institute/ School',
  'event.listMember.organizationType.7': 'Media/ Journalism',
  'event.listMember.organizationType.8': 'Expert',
  'event.listMember.organizationType.9': 'Freelancer',
  'event.listMember.organizationType.10': 'Others',
};
