export default {
  'sponsor.name': 'Sponsor',
  'sponsor.title': 'Sponsor List',
  'sponsor.titleDatePicker': 'Contribution Time',
  'sponsor.create': 'Add Sponsor',
  'sponsor.information': 'Sponsorship details',
  'sponsor.member': 'Member name',
  'sponsor.unit': 'Unit',
  'sponsor.product': 'Product sponsor',
  'sponsor.unitPrice': 'Unit Price',
  'sponsor.price': 'Price',
  'sponsor.quantity': 'Quantity',
  'sponsor.group': 'Recipient',
  'sponsor.action': 'Action',
  'sponsor.user': 'Recipient',
  'sponsor.intoMoney': 'Into Money (VND)',
  'sponsor.sponsorDate': 'Sponsor date',
  'sponsor.code': 'Member code',
  'sponsor.emailAddress': 'Email',
  'sponsor.daypay': 'Pay day',
  'sponsor.content': 'Content',
  'sponsor.attach': 'Attach',
  'sponsor.tagName': 'Sponsor',
  'sponsor.programCode': 'Program Code',
  'sponsor.delete.title': 'Sponsor remove',
  'sponsor.delete.content': 'Do you want to remove this sponsor?',
  'sponsor.event': 'Event name',
  'sponsor.tab.event': 'By event',
  'sponsor.tab.business': 'By business',
  'sponsor.totalSponsor': 'Total sponsoer',
  'sponsor.event-name': 'Event name',
  'sponsor.totalAmount': 'Into money (VND)',
  'sponsor.table.event.member': 'Sponsor name',
  'sponsor.title-by-event': 'Sponsor list by event',
};
