import { routerFormApproveMember } from '@view/ApproveMember/component/FormPage/router';
import { routerApproveMember } from '@view/ApproveMember/router';
import { routerLoginHome } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profile/router';
import {
  routerCalendar,
  routerMeetingScheduleAdd,
  routerMeetingScheduleEdit,
  routerMeetingScheduleInfo,
} from '@view/Calendar/router';
import { routerCategory } from '@view/Category/router';
import { routerFormClubs, routerFormClubsAdd } from '@view/Clubs/component/FormClubs/router';
import {
  routerFormClubsExecutiveCommittee,
  routerFormClubsExecutiveCommitteeInfo,
} from '@view/Clubs/component/ListExecutiveCommittee/component/FormExecutiveCommittee/router';
import { routerListExecutiveCommittee } from '@view/Clubs/component/ListExecutiveCommittee/router';
import {
  routerFormClubsMember,
  routerFormClubsMemberInfo,
} from '@view/Clubs/component/ListMembers/component/Member/component/FormMember/router';
import {
  routerFormClubsMemberPersonal,
  routerFormClubsMemberPersonalInfo,
} from '@view/Clubs/component/ListMembers/component/MemberPersonal/component/FormMemberPersonal/router';
import {
  routerFormClubsPartner,
  routerFormClubsPartnerInfo,
} from '@view/Clubs/component/ListMembers/component/Partner/component/FormPartner/router';
import { routerListClubsMemberTab } from '@view/Clubs/component/ListMembers/router';
import { routerResultSearchPage } from '@view/Clubs/component/ResultSearchPage/router';
import { routerClubs } from '@view/Clubs/router';
import { routerContacts } from '@view/Contacts/router';
import { routerCost } from '@view/Cost/router';
import { routerDashboard } from '@view/Dashboard/router';
import { routerDetailFeedbackPage } from '@view/Feedback/component/DetailPage/router';
import { routerFeedback } from '@view/Feedback/router';
import { routerHomeFormAdd } from '@view/Home/component/FromAdd/router';
import { routerHome } from '@view/Home/router';
import {
  routerFormExecutiveCommittee,
  routerFormExecutiveCommitteeInfo,
} from '@view/MemberAndPartner/component/ExecutiveCommittee/component/FormExecutiveCommittee/router';
import { routerHistoryFees } from '@view/MemberAndPartner/component/HistoryFees/router';
import { routerHistorySponsors } from '@view/MemberAndPartner/component/HistorySponsors/router';
import {
  routerFormClubsExecutiveCommitteeClubInfo,
  routerFormClubsExecutiveCommitteeClubs,
} from '@view/MemberAndPartner/component/ListExecutiveCommittee/component/FormExecutiveCommittee/router';
import {
  routerFormMember,
  routerFormMemberInfo,
} from '@view/MemberAndPartner/component/Member/component/FormMember/router';
import {
  routerFormMemberPersonal,
  routerFormMemberPersonalInfo,
} from '@view/MemberAndPartner/component/MemberPersonal/component/FormMemberPersonal/router';
import {
  routerFormPartner,
  routerFormPartnerInfo,
} from '@view/MemberAndPartner/component/Partner/component/FormPartner/router';
import { routerMemberAndPartnerTabs } from '@view/MemberAndPartner/router';
import { routerMemberAndPartnerApproveMember } from '@view/MemberAndPartnerApproveMember/router';
import { routerMemberRankAdd, routerMemberRankEdit } from '@view/MemberRank/component/router';
import { routerMemberRank } from '@view/MemberRank/router';
import { routerNews, routerNewsForm, routerNewsFormAdd } from '@view/News/router';
import {
  routerNotification,
  routerNotificationAdd,
  routerNotificationEdit,
  routerNotificationInfo,
} from '@view/Notification/router';
import { routerPageError } from '@view/PageError/router';
import { routerPrivacyPolicy } from '@view/PrivacyPolicy/router';
import { routerRoles, routerRolesAdd, routerRolesEdit, routerRolesInfo } from '@view/Roles/router';
import { routerSetting } from '@view/Setting/router';
import { routerSponsor } from '@view/Sponsor/router';
import { routerUser } from '@view/User/router';

import { routerEventDesignLandingPage } from '../view/Event/component/DesignLandingPage/router';
import { routerFormEvent } from '../view/Event/component/Form/router';
import { routerEventListMember } from '../view/Event/component/ListMember/router';
import { routerEvent } from '../view/Event/router';
import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerViewProfile,
  routerDashboard,
  routerHome,
  routerNews,
  routerNewsForm,
  routerNewsFormAdd,
  routerUser,
  routerRoles,
  routerRolesAdd,
  routerRolesEdit,
  routerRolesInfo,
  routerClubs,
  routerMemberAndPartnerApproveMember,
  routerApproveMember,
  routerMemberAndPartnerTabs,
  routerEvent,
  routerEventListMember,
  routerEventDesignLandingPage,
  routerFormEvent,
  routerCost,
  routerSponsor,
  routerNotification,
  routerNotificationAdd,
  routerNotificationEdit,
  routerNotificationInfo,
  routerFeedback,
  routerCalendar,
  routerFormClubsAdd,
  routerMeetingScheduleAdd,
  routerMeetingScheduleEdit,
  routerMeetingScheduleInfo,
  routerCategory,
  routerFormExecutiveCommittee,
  routerFormMember,
  routerFormPartner,
  routerSetting,
  routerFormExecutiveCommitteeInfo,
  routerFormMemberInfo,
  routerFormPartnerInfo,
  routerHistoryFees,
  routerHistorySponsors,
  routerFormClubs,
  routerContacts,
  routerFormMemberPersonal,
  routerFormMemberPersonalInfo,
  routerListExecutiveCommittee,
  routerListClubsMemberTab,
  routerFormClubsMember,
  routerFormClubsMemberInfo,
  routerFormClubsMemberPersonal,
  routerFormClubsMemberPersonalInfo,
  routerFormClubsPartner,
  routerFormClubsPartnerInfo,
  routerFormClubsExecutiveCommittee,
  routerFormClubsExecutiveCommitteeInfo,
  routerFormClubsExecutiveCommitteeClubs,
  routerFormClubsExecutiveCommitteeClubInfo,
  routerResultSearchPage,
  routerDetailFeedbackPage,
  routerPrivacyPolicy,
  routerHomeFormAdd,
  routerMemberRank,
  routerMemberRankAdd,
  routerMemberRankEdit,
  routerFormApproveMember,
  routerPageError,
];

export const publicPage: IRouter[] = [routerLoginHome, routerPrivacyPolicy, routerPageError];
